<template>
  <div>
    <section class="dashboardFilterHeader">
      <header-filters
        :filter-names="['calendar_type']"
        @onChange="onFiltersChange"
      />
      <div class="dateFilterCard">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          offset-x
          left
          :nudge-right="126"
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedDate"
              v-bind="attrs"
              label=""
              readonly
              append-icon="mdi-calendar"
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            @input="menu1 = false"
          />
        </v-menu>
      </div>
    </section>

    <v-container
      fluid
      class="chargingDashboardContent"
    >
      <v-row dense>
        <!-- Card 1 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span class="cardTitle">Total Revenue</span>
              <div class="dateFilterCard">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  offset-x
                  left
                  :nudge-right="126"
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedDate"
                      v-bind="attrs"
                      label=""
                      readonly
                      append-icon="mdi-calendar"
                      hide-details
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu2 = false"
                  />
                </v-menu>
              </div>
            </div>
            <v-card-text>
              <p class="card-value hightlightCol">
                $41,568
              </p>
              <div class="card-details">
                <Icons
                  name="chart-icon"
                  class="cardIcon decrease"
                />
                <span class="percentText">5% Decrease</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 2 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span class="cardTitle">Users This Month</span>
            </div>
            <v-card-text>
              <p class="card-value hightlightCol">
                112
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 3 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span class="cardTitle">New Users</span>
            </div>
            <v-card-text>
              <p class="card-value hightlightCol">
                $35,589
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div class="customDataTable">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.chargelocation`]="{ item }">
          <div>
            <strong>{{ item.chargelocation.name }}</strong><br>
            <span class="smallTableText">{{ item.chargelocation.address }}</span>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
export default {
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
    Icons: () => import("@/components/base/icons.vue"),

  },

  data() {
    return {
      menu: false,
      date: new Date().toISOString().substr(0, 10), // Initialize with today's date
      menu1: false,
      menu2: false,
      headers: [
        { text: 'Charger ID', value: 'chargerid' },
        { text: 'Charge Location', value: 'chargelocation' },
        { text: 'Charger Number', value: 'chargenumber' },
        { text: 'Type Of Charger', value: 'typeofcharger' },
        { text: 'Payment', value: 'payment' }
      ],
      items: [
      {
        chargerid: '123',
        chargelocation: { name: 'Icon Parking, Patriot', address: '376 Greenwich Street, New York' },
        chargenumber: 'E001',
        typeofcharger: 'Public',
        payment: '1000'
      },
      {
        chargerid: '124',
        chargelocation: { name: 'Garage Botton', address: '211 North End Ave, New York' },
        chargenumber: 'E002',
        typeofcharger: 'Private',
        payment: '800'
      },
      {
        chargerid: '125',
        chargelocation: { name: 'Essex Municipal Parking', address: '107 Essex Street, New York' },
        chargenumber: 'M003',
        typeofcharger: 'Public',
        payment: '4592'
      },
      {
        chargerid: '126',
        chargelocation: { name: 'Gansevoort Street', address: '99 Gansevoort Street, New York' },
        chargenumber: 'E004',
        typeofcharger: 'Private',
        payment: '5636'
      },
      {
        chargerid: '127',
        chargelocation: { name: 'Gramercy Plaza Parking', address: '130 East 18th St, New York' },
        chargenumber: 'E005',
        typeofcharger: 'Public',
        payment: '3699'
      },
      {
        chargerid: '128',
        chargelocation: { name: 'Port Authority', address: '625 8th Ave, New York' },
        chargenumber: 'E006',
        typeofcharger: 'Private',
        payment: '7593'
      },
      {
        chargerid: '129',
        chargelocation: { name: 'Hudson Yards', address: '20 Hudson Yards, New York' },
        chargenumber: 'E007',
        typeofcharger: 'Public',
        payment: '2666'
      },
      {
        chargerid: '130',
        chargelocation: { name: 'Battery Park City', address: '75 Battery Pl, New York' },
        chargenumber: 'E008',
        typeofcharger: 'Private',
        payment: '5692'
      },
      {
        chargerid: '131',
        chargelocation: { name: 'Brookfield Place', address: '230 Vesey St, New York' },
        chargenumber: 'E009',
        typeofcharger: 'Public',
        payment: '5996'
      },
      {
        chargerid: '132',
        chargelocation: { name: 'Central Park West', address: '400 Central Park West, New York' },
        chargenumber: 'E010',
        typeofcharger: 'Private',
        payment: '1003'
      }
    ],
      options: {
        type: "week",
        mode: "stack",
      },
      // types: ["month", "week", "day", "4day"],
      types: ["week", "day"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      // weekdays: [
      //   { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      //   { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      //   { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      //   { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      // ],
      value: "",
      events: [],
      //   colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange"],

      // show event
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
    
    
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsBookings/getLoading",
      list: "csmsBookings/getList",
    }),
    formattedDate() {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(this.date).toLocaleDateString('en-GB', options);
    },
  },
  //   watch: {
  //     "options.calendar_type": {
  //       handler: function () {
  //       },
  //     },
  //   },
  beforeDestroy() {
    const params = {
      name: "charging_dashboard",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    onFiltersChange(v) {
      this.options = v;
      this.fetchData();
    },
    fetchData: debounce(async function (val) {
      await this.$store.dispatch("csmsBookings/list", this.options);
      await this.getEvents(val);
      ("");
    }, 100),
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

$primaryColor: #23BDAA

.chargingDashboardContent
  padding-left:0
  padding-right:0

.chargingDashboardContent .row--dense > [class*=col-]
  padding: 12px !important

.dashboard-card
  position: relative
  min-height: 175px
  margin: 0
  border-radius: 7px

  &::before
    content:''
    box-sizing: border-box
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 9
    z-index: 1
    background: #FFFFFF
    border: 1px solid #F3F3F3
    box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
    border-radius: 7px

  &::after
    content:''
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 3px
    background: #F9F9F9
    box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
    border-radius: 7px

  .cardTopBar
    width: 100%
    border-bottom: 1px solid #f3f3f3
    padding: 1.3rem 1.6rem 0.7rem
    margin-bottom: 2rem
    z-index: 2
    position: relative
    display: flex
    align-items: center
    flex-wrap: wrap
    min-height: 62px

  .cardTitle
    font-size: 1.1rem
    color: #000
    font-family: $poppinSemi

  .card-value 
    font-size: 2.8rem
    font-family: $poppinSemi
    z-index: 2
    position: relative

    &.hightlightCol
      color: #23BDAA

    .smallValueText
      font-size: 0.875rem
      font-family: &poppinReg

  .v-card__text
    display: flex
    flex-wrap: wrap
    padding: 1.3rem 1.6rem 0.7rem
    align-items: center

  .card-details 
    z-index: 2
    position: relative
    display: flex
    flex-direction: column
    margin-left: auto

    .cardIcon
      &.increase
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #10b981

      &.decrease 
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #ef4444

    span.percentText
      display: flex
      margin-top: 0.3rem
      font-size: 0.75rem
      font-family: $poppinMed

  
  .MapLinkCard
    display: flex
    margin-left: auto
    color: #C1C7C6
    text-decoration: none
    font-family: $poppinReg
    font-size: 0.875rem
  .cardListWrap
    display: flex
    position: relative
    padding-top: 0
    padding-bottom: 0
    z-index: 2
    ul
      display: flex
      flex-wrap: wrap
      padding: 0
      margin-bottom: 1rem
      li
        font-size: 0.9375rem
        color: #000
        display: flex
        flex-wrap: wrap
        width: 50%
        margin-bottom: 0.4rem
    &.fullWidth
      ul
        li
          width: 100%

.dateFilterCard
  display: flex
  margin-left: auto
  width:35%
  .v-input
    padding-top:0 !important
    margin-bottom: 0 !important
    margin-top:0 !important
    :deep .v-input__control
      .v-input__slot
        background: #F6F6F6
        border-radius: 6px
        padding: 0 0.8rem
        &:before
          display: none
        &:after
          display: none
        .v-text-field__slot
          input
            font-size: 0.875rem
            color: #22272E
        .v-input__append-inner
          .v-input__icon
            color: $primaryColor
            justify-content: flex-end
            i
              color: $primaryColor
              font-size: 1rem

.dashboardFilterHeader
  display: flex
  align-items: center
  padding: 6px 12px 0
  .headerBarWrap
    align-items: center
    padding: 0px 0

  :deep .filterBtn
    display: none !important

  .dateFilterCard
    width: 10.5%
    .v-input
      :deep .v-input__control
        .v-input__slot
          background: #f6f9fc !important
          border-radius: 6px
          padding: 0 0.8rem
          border: 1px solid #ddd

.drawerOpen .dashboardFilterHeader .dateFilterCard
  width: 12%

.drawerOpen .dashboard-card .cardTitle
  font-size: 1rem

.drawerOpen .chargingDashboardContent .row--dense > [class*=col-]
    padding: 12px 6px !important

.drawerOpen .dashboard-card .cardTopBar
  padding: 1rem 1rem 0.7rem

.drawerOpen .dashboard-card .dateFilterCard
  width: 48%

.customDataTable
  padding: 12px
  .smallTableText
    color: #8E8E8E
    font-weight: 300
    font-size: 0.675rem
</style>
